<template>
  <!-- 解密消息 -->
  <el-dialog
    :title="$t('unlockMessage')"
    :visible.sync="$store.state.vip.decryptModeStatus.visible"
    :close-on-click-modal="false"
    center
    :modal-append-to-body="false"
    :customClass="'decryptMode confirmPaymentClass'"
    :before-close="beforeClose"
  >
    <!-- 输入密码 确认节解密 -->
    <transition name="transitionStyle">
      <div class="confirmPayment">
        <form>
          <div class="title">{{ $t("checkPwd") }}</div>
          <el-input
            prefix-icon="iconfont_Me icon-a-lujing13297"
            v-model="password1"
            class="input-one"
            :type="flagType"
          >
            <i
              slot="suffix"
              style="margin-top:8px;font-size:18px;"
              :class="[
                this.flag
                  ? 'el-input__icon iconfont_Me icon-Show'
                  : 'el-input__icon iconfont_Me icon-Hide',
              ]"
              @click="getFlag()"
            ></i>
          </el-input>
        </form>
        <div
          class="nextStepButton"
          :class="disable ? 'disable' : ''"
          @click.stop="pay"
        >
          <i class="iconfont_Me icon-check iconStyle"></i>
        </div>
      </div>
    </transition>
  </el-dialog>
</template>
<script>
import { mapMutations } from "vuex";
import Bus from "@/utils/bus.js";
export default {
  data() {
    return {
      password1: "",
      disable: true,
      flag: false,
      flagType: "password",
    };
  },
  components: {},
  props: {},
  watch: {
    password1: {
      handler(val) {
        if (val) {
          this.disable = false;
        } else {
          this.disable = true;
        }
      },
    },
  },
  mounted() {
    this.password1 = "";
  },
  computed: {
    messageId() {
      return this.$store.state.vip.decryptModeStatus.messageId;
    },
  },
  methods: {
    ...mapMutations([
      "setPaySucceTipsStatus",
      "setPayErrorTipsStatus",
      "setIsVipModeStatus",
      "setAuth",
    ]),
    beforeClose() {
      delete this.$store.state.vip.currentDecryptMessages[this.messageId];
      this.$store.commit("setDecryptModeStatus", {
        visible: false,
        messageId: null,
      });
    },
    //输入密码正确，解密
    async pay() {
      if (!this.password1) {
        return false;
      }
      Bus.$emit("decrypt", this.password1, this.messageId);
    },
    getFlag() {
      this.flag = !this.flag;
      this.flagType = this.flag ? "text" : "password"; //text为显示密码；password为隐藏密码
    },
  },
};
</script>
<style lang="stylus" scoped>
.transitionStyle-enter-active, .transitionStyle-leave-active {
  transition: opacity 0.3s, transform 0.3s;
}

.transitionStyle-enter {
  opacity: 0;
  transform: translate3d(120px, 0, 0);
}

.transitionStyle-leave-to {
  opacity: 0;
  transform: translate3d(-120px, 0, 0);
}

.dialogHeader {
  width: 100%;
  height: 68px;
  box-sizing: border-box;
  padding: 17px 16px 0 0;

  .iconStyle {
    float: right;
    cursor: pointer;
    font-size: 16px;
    color: #CCCCCC;
  }
}
</style>
<style lang="scss" scoped>
:deep .decryptMode {
  width: 500px;
  // height: 528px;
  overflow: hidden;
  background: linear-gradient(180deg, #ffefd5, #ffffff 15%);
  // background: linear-gradient(180deg,#ffefd5, #ffffff 16%, #f5f5f5 35%);
  border-radius: 8px;

  // 支付方式
  &.paymentMethodClass {
    width: 500px;
    // height: 499px;
    background: linear-gradient(180deg, #ffefd5, #ffffff 16%, #f5f5f5 35%);
    border-radius: 8px;

    .el-dialog__body {
      padding-top: 30px;
    }
  }

  // 确认支付
  &.confirmPaymentClass {
    // height: 425px;

    .el-dialog__body {
      padding: 50px 80px;
    }

    .confirmPayment {
      display: flex;
      flex-direction: column;
      width: 100%;

      .el-input__prefix {
        left: 10px;
      }

      .el-input__inner {
        padding-left: 30px;
      }

      .input-one {
        margin: 18px 0;
      }

      .icon-a-lujing13297,
      .el-input__clear {
        color: #000;
      }

      text-align: left;
      width: 100%;

      .title {
        font-size: 16px;
        color: #343434;
        letter-spacing: 0.8px;
      }

      .nextStepButton {
        margin-top: 30px;
        cursor: pointer;
      }
    }
  }

  .el-dialog__body {
    padding-top: 40px;
    display: flex;
    justify-content: center;

    .userInfo {
      display: flex;
      margin-bottom: 30px;

      .head {
        width: 64px;
        height: 64px;
        margin-right: 22px;
        border-radius: 50%;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .info {
        display: flex;
        flex-direction: column;
        justify-content: space-around;

        .email {
          font-size: 13px;
          color: #919191;
        }

        .valid {
          font-size: 12px;

          span {
            color: #ffb293;
          }
        }
      }
    }

    .vip-content {
      text-align: center;
      width: 338px;
      height: 125px;
      background: linear-gradient(
        160deg,
        #fff5e6 0%,
        #fef1dd 48%,
        #f5d8a6 100%
      );
      background: url("../../../assets/images/pay/g1.png") no-repeat;
      background-size: contain;
      padding: 24px 64px;
      box-sizing: border-box;
      color: #1a1a1a;

      .vipIcon {
        font-size: 16px;
        margin-right: 8px;
      }

      .vip-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
      }

      .cost {
        margin-top: 22px;
        padding: 8px 26px;
        background: rgba($color: #fdd7a3, $alpha: 0.89);
        border-radius: 18px;
        font-size: 14px;
      }
    }

    .vip-info {
      margin-top: 24px;
      width: 338px;
      text-align: left;

      .tit {
        font-size: 15px;
        color: #1a1a1a;
        margin-bottom: 13px;
      }

      .con {
        color: #808080;
        line-height: 22px;
      }
    }

    .nextStepButton {
      height: 46px;
      border-radius: 4px;
      background: #33cc66;
      display: flex;
      align-items: center;
      justify-content: center;

      &.disable {
        background: #d2d2d2;
      }

      .iconStyle {
        font-size: 24px;
        color: #ffffff;
      }
    }

    .renewStepButton {
      height: 46px;
      margin-top: 30px;
      border-radius: 4px;
      background: linear-gradient(124deg, #f7c988 13%, #d4964e 94%);
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      font-size: 15px;
    }

    .renewButtonHover {
      cursor: pointer !important;
      background: linear-gradient(124deg, #f7c988 13%, #d4964e 94%);
      transition: background 0.3s;
    }

    .agreement {
      text-align: left;
      margin-top: 8px;
      font-size: 13px;
    }

    .vipagree {
      color: #45d073;
      cursor: pointer;
    }

    .paymentMethod {
      .price-content {
        width: 343px;
        height: 121px;
        line-height: 121px;
        padding-left: 26px;
        box-sizing: border-box;
        background: #f9d9a9;
        font-size: 16px;
        background: url("../../../assets/images/pay/p.png") no-repeat;
        background-size: cover;
        border-radius: 8px;
        box-shadow: 0px 6px 10px 0px rgba(226, 157, 76, 0.31);

        .price {
          font-size: 28px;
        }
      }

      .pay-method {
        width: 342px;
        height: 154px;
        background: #ffffff;
        border-radius: 8px;
        overflow: hidden;
        margin-top: 20px;

        .line {
          height: 1px;
          background: rgba(0, 0, 0, 0.1);
        }

        .cny,
        .usd {
          height: 50%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0 25px 0 16px;
          box-sizing: border-box;

          .flex {
            display: flex;
            align-items: center;
            font-size: 16px;
            color: #9a9a9c;
          }
        }

        .el-image {
          margin-right: 9px;
        }
      }

      // 余额
      .balance {
        padding: 15px;
        background: rgba($color: #e6f2ea, $alpha: 0.8);
        border-bottom-right-radius: 8px;
        border-bottom-left-radius: 8px;
        color: #33cc66;
      }
    }
  }

  .back {
    position: absolute;
    top: 20px;
    left: 20px;
    padding: 0;
    background: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    font-size: 16px;
  }
}
</style>
